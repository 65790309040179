import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "./Image.scss";

const Image = ({ className, data }) => {
  const image = getImage(data);
  return (
    <div className="zoom-out">
      <GatsbyImage image={image} alt="" className={`zoom-out__img ${className}`} />
      <span className="zoom-out__rev"></span>
    </div>
  );
};

Image.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default Image;
