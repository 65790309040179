import React from "react";
import PropTypes from "prop-types";
import Image from "../Image";
import Section from "../Section";
import { Link } from "gatsby";

import "./Section2.scss";

const DistrictSection2 = ({ data }) => {
  return (
    <Section className="two-d">
      <div className="section__stripes-2d jsSectionStripes2d"></div>
      <div className="two-d-grid jsTwoDGrid">
        <div className="div div1">
          <Image
            className="jsDS2Img1"
            data={data.image2}
          />
        </div>
        <div className="div div2">
          <Image
            className="jsDS2Img2"
            data={data.image1}
          />
        </div>
        <div className="div div3">
          <h2 className="title title--district3 jsTwoDistrict">
            {data.urlText}
          </h2>
          <ul className="color-titles">
            {data.content.map((item, index) => (
              <li key={index}>
                <h3 className="title title--h2 color-titles__title">
                  {item.title}
                </h3>
                <p dangerouslySetInnerHTML={{ __html: item.description }} />
              </li>
            ))}
            <li>
              <Link className="btn" to={data.button.url}>
                {data.button.title}
              </Link>
            </li>
          </ul>
        </div>
        <div className="div div4">
          <Image
            className="jsDS2Img3"
            data={data.image3}
          />
        </div>
        <div className="div div5">
          <Image
            className="jsDS2Img4"
            data={data.image4}
          />
          <div className="text-w-border jsTwoDistrict">
            <span className="ui-bold">
              <a
                className="link"
                href={`https://${data.urlLabel}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="link__text">{data.urlLabel}</span>
              </a>
            </span>
          </div>
        </div>
      </div>
    </Section>
  );
};

DistrictSection2.propTypes = {
  data: PropTypes.object,
};

export default DistrictSection2;
