import React, {useEffect, useCallback } from "react"
import { graphql } from 'gatsby';
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { useData } from '../utils/DataProvider'
import LayoutInner from "../components/LayoutInner"
import {
  moveLines,
  imageReveal,
  revealText } from "../animations/Animate"
import Hero from "../components/Hero"
import SEO from "../components/Seo"
import ImageText from "../components/ImageText";
import DistrictSection2 from "../components/District/Section2";
gsap.registerPlugin(ScrollTrigger);


const DistrictPage = ({data, location}) => {
  const [state, setState] = useData()
  const districtData = data.districtQuery;


  useEffect(() => {
    setState({ ...state, color: districtData.meta.color});
  }, [setState, districtData]);

  const districtAnimation = useCallback(() => {
    moveLines('-350px')
  })

  const section1District = useCallback(() => {
    gsap.to('.jsCoverRevealS1', {
      x: '100%',
      duration: 1.2,
      ease: "power4.inOut",
    })
    // DISABLED per client request
    // revealText('.jsTitleLines .ui-block__text')

    // gsap.from('.jsTitleLines', {
    //   duration: 1.2,
    //   opacity: 0,
    //   y: 30,
    //   stagger: 0.2,
    //   ease: "power4.inOut",
    // })

    // gsap.from('.jsTitleLines em', {
    //   duration: 3,
    //   scaleX: 0,
    //   transformOrigin: 'left center',
    //   stagger: 0.8,
    //   ease: "power4.inOut",
    //   scrollTrigger: {
    //     trigger: '.jsCoverImage',
    //     start: '-50%',
    //     end: '50%',
    //     scrub: true,
    //   }
    // })
  })

  const section2District = useCallback(() => {
    gsap.from('.jsSectionStripes2d', {
      duration: 2,
      x: "-100%",
      ease: "power4.inOut",
      scrollTrigger: {
        trigger: '.jsSectionStripes2d',
        start: 'top 100%',
        //markers: true
      }
    })

    // DISABLED per client request
    // gsap.from(['.jsTwoDistrict', 'color-titles'], {
    //   duration: 1.4,
    //   y: 40,
    //   opacity: 0,
    //   stagger: 0.3,
    //   ease: "power4.inOut",
    //   scrollTrigger: {
    //     trigger: '.jsTwoDistrict',
    //     start: 'top 80%',
    //   }
    // })
    // gsap.from('.color-titles li', {
    //   duration: 2,
    //   opacity: 0,
    //   y: 40,
    //   stagger: 0.3,
    //   ease: "power4.inOut",
    //   scrollTrigger: {
    //     trigger: '.jsTwoDistrict',
    //     start: 'top 80%',
    //   }
    // })
    imageReveal('.jsDS2Img1', 'top 100%')
    imageReveal('.jsDS2Img2', 'top 80%')
    imageReveal('.jsDS2Img3', 'top 80%')
    imageReveal('.jsDS2Img4', 'top 80%')
  })


  useEffect(() => {
    districtAnimation();
    section1District();
    section2District();
  }, []);

  return (
    <LayoutInner location={location}>
      <SEO title={districtData.meta.title} />
      <Hero data={districtData.heroQuery} />
      <ImageText data={districtData.sectionsQuery.section1} order={'content,image'} size={'long'} />
      <DistrictSection2 data={districtData.sectionsQuery.section2} />
    </LayoutInner>
  )
}

export default DistrictPage


export const query = graphql`
  query District {
    districtQuery: districtPageJson {
      meta {
        title
        shareImage
        keywords
        color
      }
      heroQuery: hero {
        title
        stripes
      }
      sectionsQuery: sections {
        section1 {
          content
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        section2 {
          content {
            description
            title
          }
          button {
            title
            url
          }
          urlText
          urlLabel
          image1 {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          image2 {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          image3 {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          image4 {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;

